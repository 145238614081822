<template>
	<div>
		<header>
			<ul>
				<li>
					<div class="tab-box">
						<router-link to="/project-page">
							<font-awesome-icon icon="book" :style="{ width: '20px', height: 'auto' }" />
							Project
						</router-link>
					</div>
				</li>
				<li>
					<div class="tab-box">
						<router-link to="/system-users">
							<font-awesome-icon icon="user" :style="{ width: '20px', height: 'auto' }" />
							System users
						</router-link>
					</div>
				</li>
				<li>
					<div class="tab-box">
						<button class="btn btn-link" @click="logout">
							<font-awesome-icon icon="sign-out-alt" :style="{ width: '20px', height: 'auto' }" />
							Logout
						</button>
					</div>
				</li>
			</ul>
		</header>
		<div class="content-wrapper">
			<div class="container">
				<h2 class="label-header">System User</h2>
				<button class="btn btn-primary mb-3 mt-1" @click="openModal" :disabled="isLoading">Add System User</button>
				<div class="alert alert-danger alert-dismissable" v-if="showErrorPageAlert">
			{{ errorMessage }}
		</div>
				<div class="card">
					<div class="card-body" v-if="!isLoading">
						<table class="table">
							<thead>
								<tr>
									<th></th>
									<th>Email</th>
									<th>Username</th>
									<th>Password</th>
									<th width="150px">Pass Code</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								<template v-if="clientList.length > 0">
									<tr v-for="(item, i) in clientList" :key="i">
										<th>{{ i + 1 }}</th>
										<td>{{ item.client_email }}</td>
										<td>{{ item.client_username }}</td>
										<td>{{ item.password }}</td>
										<td>{{ item.pass_code }}</td>
										<td>
											<button class="btn btn-sm btn-primary" style="margin-right: 10px;" @click.stop="editItem(item)">Edit</button>
											<button class="btn btn-sm btn-danger" @click="deleteItem(item)">Delete</button>
										</td>
									</tr>
								</template>
								<template v-if="clientList.length === 0">
									<tr>
										<td style="text-align: center" colspan="6">No Course Added Yet</td>
									</tr>
								</template>
							</tbody>
						</table>
					</div>
					<div class="card-body loader" v-if="isLoading">
						<div class="spinner-border"></div>
						<h6 style="margin-top: 15px; font-size: 17px;">{{ loadingMessage }}</h6>
					</div>
				</div>
			</div>
		</div>
		<div class="modal fade" ref="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLabel">{{ modalTitle }}</h5>
						<button type="button" class="btn-close" @click="modal.hide()" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<div class="alert alert-danger alert-dismissable" v-if="showErrorAlert">
							{{ errorMessage }}
						</div>
						<form @submit.prevent="onSubmit" novalidate>
							<div class="form-group mb-2">
								<label for="">Email</label>
								<input type="text" v-model="vv.email.$model" placeholder="Enter email" class="form-control" :readonly="editMode">
								<span class="error">{{ vv?.code?.$errors[0]?.$message }}</span>
							</div>
							<div class="form-group mb-3">
								<label for="">Username</label>
								<input type="text" v-model="vv.username.$model" placeholder="Enter username" class="form-control">
								<span class="error">{{ vv?.title?.$errors[0]?.$message }}</span>
							</div>
							<div class="form-group mb-3">
								<label for="">Password</label>
								<input type="text" v-model="vv.password.$model" placeholder="Enter password" class="form-control">
								<span class="error">{{ vv?.title?.$errors[0]?.$message }}</span>
							</div>
							<div class="form-group">
								<button class="btn btn-primary" :disabled="vv.$invalid || isProcessing" style="margin-right: 15px;" v-if="editMode">
									<span v-if="isProcessing" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
									<span v-if="isProcessing" style="padding-left: 15px !important;">Updating.....</span>
									<span v-if="!isProcessing">Update</span>
								</button>
								<button class="btn btn-primary" :disabled="vv.$invalid || isProcessing" v-if="!editMode">
									<span v-if="isProcessing" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
									<span v-if="isProcessing" style="padding-left: 15px !important;">Saveing.....</span>
									<span v-if="!isProcessing">Save</span>
								</button>
							</div>
						</form>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" @click="modal.hide()">Close</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { reactive } from "vue";
import { Modal } from 'bootstrap'
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { authService } from '@/services/authentication.service'
import { storageService } from '@/services/storage.service'

export default {
	name: 'Client',
	setup() {
		const fform = reactive({
			email: '',
			username: '',
			password: ''
		})
		const rules = {
			email: { required, email },
			username: { required },
			password: { required }
		}
		const vv = useVuelidate(rules, fform);
		return {
			vv,
			fform
		}
	},
	data() {
		return {
			isLoading: true,
			modal: null,
			modalTitle: '',
			editMode: false,
			isProcessing: false,
			loadingError: false,
			isProjectAvaliable: false,
			loadingMessage: 'Loading Users......',
			showErrorAlert: false,
			showErrorPageAlert: false,
			errorMessage: '',
			editIndex: 0,
			clientList: []
		};
	},
	mounted () {
		this.modal = new Modal(this.$refs.exampleModal)
		this.loadContent()
	},
	methods: {
		loadContent () {
			this.isLoading = true
			this.loadingError = false
			authService.fetchClient()
				.then((response) => {
					this.isLoading = false
					if(parseInt(response.data.message_code) === 200){
						this.clientList = response.data.client_list
					}else{
						this.loadingError = true
					}
				})
				.catch((error) => {
					this.isLoading = false
					this.loadingError = true
					console.log(error.message)
				})
		},
		openModal () {
			this.modalTitle = 'Add User'
			this.editMode = false
			this.fform.email = ''
			this.fform.username = ''
			this.fform.password = ''
			this.modal.show()
		},
		editItem (item) {
			this.fform.email = item.client_email
			this.fform.password = item.password
			this.fform.username = item.client_username
			this.editMode = true
			this.modal.show()
		},
		onSubmit () {
			this.vv.$touch();
			if (this.vv.$invalid) return;
			this.isProcessing = true
			this.showErrorAlert = false
			const postData = {
				email: this.fform.email,
				password: this.fform.password,
				username: this.fform.username
			}
			if(this.editMode){
				authService.updateClient(postData)
					.then((response) => {
						this.isProcessing = false
						if(parseInt(response.data.message_code) === 200){
							this.modal.hide()
							this.fform.email = ''
							this.fform.password = ''
							this.fform.username = ''
							this.loadContent()
						}else{
							this.showErrorAlert = true
							this.errorMessage = 'Error Saving Data'
						}
					})
					.catch((error) => {
						console.log(error.message)
						this.isProcessing = false
						this.showErrorAlert = true
						this.errorMessage = 'Error Connecting To Server'
					})
			}else{
				authService.storeClient(postData)
					.then((response) => {
						this.isProcessing = false
						if(parseInt(response.data.message_code) === 200){
							this.modal.hide()
							this.fform.email = ''
							this.fform.password = ''
							this.fform.username = ''
							this.loadContent()
						}else{
							this.showErrorAlert = true
							this.errorMessage = 'Error Saving Data'
						}
					})
					.catch((error) => {
						console.log(error.message)
						this.isProcessing = false
						this.showErrorAlert = true
						this.errorMessage = 'Error Connecting To Server'
					})
			}
		},
		deleteItem(item){
			this.$swal({
				text: `Are you sure to delete this user?`,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes, Delete',
				allowOutsideClick: false
			}).then((responses) => {
				if(responses.isConfirmed) {
					this.showErrorPageAlert = false
					this.isLoading = true
					const postData = {
						email: item.client_email
					}
					authService.deleteClient(postData)
						.then((response) => {
							this.isLoading = false
							if(parseInt(response.data.message_code) === 200){
								this.loadContent()
							}else{
								this.showErrorPageAlert = true
								this.errorMessage = 'Error Deleting User. Please try again'
							}
						})
						.catch((error) => {
							console.log(error.message)
							this.isLoading = false
							this.showErrorPageAlert = true
							this.errorMessage = 'Error Connecting To Server'
						})
				}
			})
		},
		logout () {
			storageService.removeLogin()
			setTimeout(() => {
				this.$router.push('/')
			}, 200)
		}
	}
};
</script>


<style scoped>
header{
	display: flex;
	justify-content: flex-end;
	/*background: #5cbaea;*/
	background: #09568d;
	/*box-shadow: 0px 0px 20px 6px #1e6e96 inset;*/
	box-shadow: 0px 0px 20px 6px #76a0c3 inset;
	padding: 10px 5px;
	height: 70px;
}
header ul li{
	display: inline-block;
	text-transform: capitalize;
	padding: 10px 7px;
	min-width: 100px;
}
header ul li .tab-box a, header ul li .tab-box .btn{
	display: flex;
	flex-direction: row;
	justify-content: center;
	text-decoration: none !important;
	align-items: center;
	/*color: #36383a;*/
	color: #fbf8f8;
	margin-right: 15px;
}
header ul li .tab-box a svg, header ul li .tab-box .btn svg{
	margin-right: 10px;
}
.content-wrapper{
	background-color: #f4f5f8;/*#ebedf1;*/
	width: 100%;
	min-height: calc(100vh - 70px);
	padding-top: 70px;
}
.card .card-body.loader{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
</style>